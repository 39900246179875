import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,  Validators  } from '@angular/forms';
import { HttpclientService } from '../../../application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
 

@Component({
  selector: 'app-member-login-pop',
  templateUrl: './member-login-pop.component.html',
  styleUrls: ['./member-login-pop.component.scss']
})
export class MemberLoginPopComponent implements OnInit {

  envSiteName:any;

  constructor(private formBuilder: FormBuilder, private httpclientService: HttpclientService) {
    this.envSiteName=environment.siteName;
    this.createLoginForm();
   }

   loginForm: FormGroup;
   submitted = false;
   loginUser:any;
   Message:any;

   get f() { return this.loginForm.controls; }

  ngOnInit(): void {
  }


  onSubmit() {

    this.submitted = true;
    if (this.loginForm.invalid) {
        return;
    }     
    var formData: any = new FormData();

    formData.email = this.loginForm.get('email').value;     
    formData.password=this.loginForm.get('password').value;
    formData.acceptTerms=this.loginForm.get('acceptTerms').value;
    formData.siteName=this.envSiteName;



    this.loginUser = false;    
    this.httpclientService.postFormData('https://newapi.nathanark.com/api/AllForms/Login',  this.getFormUrlEncodedData(formData))
      .subscribe(
        items => {
          this.loginUser = items;
          //this.loginUser = true;
          //console.log("log in",this.loginUser)
 
          switch(this.loginUser)
          {
              case 'Agency':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
                break;
              case 'Advertiser':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              break;
              case 'Other':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              break;
              case 'Publisher':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              break;
              case 'GuestAuthor':
              window.open("https://media7.io/new/Login.aspx?site="+this.envSiteName+"&user="+this.loginForm.get('email').value, "_self")
              break;
              case 'not active':

              this.Message='You need to active your account for' +this.envSiteName+', please check on your URL sent to you for account activation on signup';
                
              break;

              case 'not exist':
                this.Message = 'You need to signup for ' +this.envSiteName;
              break;

              default:
                this.Message = '*Invalid Email or Password';
              break;
          };

        });



  }
 
  createLoginForm(){
    this.loginForm = this.formBuilder.group({               
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(4)]],
      acceptTerms: [false, Validators.requiredTrue]
    });
  }


  getFormUrlEncodedData(toConvert) {
		const formBody = [];
		for (const property in toConvert) {
			const encodedKey = encodeURIComponent(property);
			const encodedValue = encodeURIComponent(toConvert[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		return formBody.join('&');
  }


  onReset() {
    this.submitted = false;
    this.loginForm.reset();
  }

}
