<div class="text-center add-center " id="reso">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalResourcesTopAD"></iframe>
</div>
<!-- <div class="text-center add-center displaynone" id="arti">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalArticlesTopAD"></iframe>
</div>
<div class="text-center add-center displaynone" id="vide">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalVideosTopAD"></iframe>
</div>
<div class="text-center add-center displaynone" id="whit">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalWhitepapersTopAD"></iframe>
</div>
<div class="text-center add-center displaynone" id="info">
    <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalInfographicsTopAD"></iframe>
</div> -->
<router-outlet></router-outlet>
<!-- <div class="row">
    <div class="col-md-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-md-3">
         <app-square-ad-videos></app-square-ad-videos>
    </div>
</div> -->
