import { Component, OnInit } from '@angular/core';
import { HttpclientService } from '../../../../application/httpclient/httpclient.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '../../../../../../../src/environments/environment';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-ondemand-webiners',
  templateUrl: './ondemand-webiners.component.html',
  styleUrls: ['./ondemand-webiners.component.scss']
})
export class OndemandWebinersComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];
  currentIndex = -1;
  title = '';
  page = 1;
  count = 0;
  pageSize = 9;
  OnDemandWebinarDatas:any;
  OnDemandWebinarLoaded:boolean;
  firstString:string;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoaded: boolean;
  MetaTagData: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string,private routeUrl: Router ) {
    this.envSiteName=environment.siteName;
   }
  ngOnInit(): void {
    this.AllOnDemandWebinars();
    this.AllMetaTagOndemand();
  }


  AllOnDemandWebinars(): void {
 // if(this.OnDemandWebinarLoaded!=true){
      this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.OnDemandWebinarLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalEvents/GetGlobalEventAllOnDemandWebinars?siteName='+this.envSiteName+'&pageNumber='+this.page)
      .subscribe(
        items => {
          const platform = isPlatformBrowser(this.platformId) ?
          'in the browser' : 'on the server';
          this.OnDemandWebinarDatas = items;
          this.count=this.OnDemandWebinarDatas[0].TotalDataCount;
          this.OnDemandWebinarLoaded = true;
          
          console.log("OnDemand AllWebinars",this.OnDemandWebinarDatas)
          this.state.set(STATE_KEY_ITEMS,<any> items)
          window.scrollTo(0,0);
        });
     // }
  }
  resetUsers(): void {
    this.OnDemandWebinarDatas = null;
    this.OnDemandWebinarLoaded = true;
  }
  
  // redirect(getUrl:any,getId:any)
  // {
  //   this.firstString = getUrl.substring(0,5);
  //   if(this.firstString ==="https")
  //   {
  //     window.open(getUrl, "_blank");
  //   }else
  //   {
  //     this.routeUrl.navigate(['/events/on-demand-webinars/'+getUrl+"/"+getId]);
  //   }
  // }

  



  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.AllOnDemandWebinars();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}

AllMetaTagOndemand(): void {
  if(this.MetaTagLoaded!=true){
  this.MetaTagLoaded = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'On-demand Webinars')
  .subscribe(
    items => {
      this.MetaTagData = items[0];
      this.MetaTagLoaded=true;
      console.log('this.MetaTagData',this.MetaTagData);


        //seo code for tags
        this.canonical.CreateCanonicalLink();

        this.titleService.setTitle(this.MetaTagData.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'},
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagData.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagData.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagData.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagData.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagData.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagData.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagData.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagData.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagData.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagData.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagData.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagData.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagData.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagData.OgImageURL}
        );
        //seo code for tags end
      });
    }
}
}
