<!-- Footer -->
<footer class="page-footer font-small blue pt-4 mt-4 FootWrap">

    <!-- Footer Links -->
    <div class="container-fluid text-center text-md-left">
        <div class="col-md-12 border-top border-color-gray ">

        </div>
        <!-- Grid row -->
        <div class="row FootRowBox">

            <!-- Grid column -->
            <div class="col-md-3 mt-md-0 pt-md-3 FootLogo">
                <img routerLink="/" [src]="logo" class="footer-logo mt-3" alt="The {{preSite}}">
            </div>
            <!-- Grid column -->

            <!-- <hr class="clearfix w-100 d-md-none pb-3"> -->

            <!-- Grid column -->
            <div class="col-md-4 mb-md-0 mb-3 mt-4 text-center email-input NewsLet">

                <form [formGroup]="footernewsLetterForm" (ngSubmit)="onSubmit()">

                    <input formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="txtNewsletterEmailFooter" type="text" placeholder="*EMAIL" class="mb-1 p-1 pl-4 pr-4 mt-2 para-3">

                    <button class="thm-btn btn-theme-success p-2 pl-5 pr-5 text-uppercase border-1 mt-1 mb-1">Newsletter Signup</button>

                </form>
                <p><a href="https://media7.com/do-not-sell-my-information" target="_blank" class="para-2 para-2-c-2 text-uppercase">do not sell my personal information</a></p>
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-3 mb-md-0 mb-3 pl-md-5 mt-2 FootMList">

                <!-- Links -->

                <ul class="footer-menu-list list-unstyled ml-md-5 pl-md-1 mt-md-4">
                    <li>
                        <a href="https://media7.com/contact-us" target="_blank">
                            <p class="para-5 footer-para-2-c-2">Advertise With Us</p>
                        </a>
                    </li>
                    <li>
                        <a>
                            <p class="para-5 footer-para-2-c-2 cursor-p" data-toggle="modal" data-target="#memberLoginModalCenter"> Member Login</p>
                        </a>
                    </li>
                    <li>
                        <a>
                            <p class="para-5 footer-para-2-c-2 cursor-p" routerLink='/privacy-policy'> Privacy</p>
                        </a>
                    </li>
                    <li>
                        <a>
                            <p class="para-5 footer-para-2-c-2 cursor-p" routerLink='/cookies'>Cookies</p>
                        </a>
                    </li>
                    <li>
                        <a>
                            <p class="para-5 footer-para-2-c-2 cursor-p" routerLink='/terms-and-service'>Terms</p>
                        </a>
                    </li>
                </ul>

            </div>

            <div class="col-md-2 mb-md-0 mb-3 social-round-icon FootIcon">

                <!-- Links -->
                <p class="para-5 para-2-c-2 mb-1 mr-connect">Connect With Us</p>
                <a *ngIf="LinkedIn !='';" href="{{LinkedIn}}" target="_blank" class="fab fa-linkedin-in"></a>
                <a *ngIf="Twitter !='';" href="{{Twitter}}" target="_blank" class="fab fa-twitter"></a>
                <a *ngIf="FaceBook !='';" href="{{FaceBook}}" target="_blank" class="fab fa-facebook"></a>

                <!-- <p class="para-2 mb-0 CatTag" *ngIf="trendingNewsData.Tag!=''; else BlankTag">{{trendingNewsData.Tag}}</p>
            <ng-template #BlankTag>
                <p class="para-2 mb-0 tag"></p>
            </ng-template> -->
            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->
        <app-scroll-top></app-scroll-top>
    </div>
    <!-- Footer Links -->
    <!-- Copyright -->
    <div class="footer-copyright text-center py-4 mt-0 text-white">Copyright © {{currentYear}} &nbsp;<a class="text-white" routerLink="/">{{Site_Name}}</a>&nbsp;| All Rights Reserved
    </div>
    <!-- Copyright -->
</footer>
<!-- Footer -->