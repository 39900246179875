import { Component, OnInit } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../../src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from 'src/app/canonical.service';

const STATE_KEY_ITEMS = makeStateKey('items');

@Component({
  selector: 'app-infographic',
  templateUrl: './infographic.component.html',
  styleUrls: ['./infographic.component.scss']
})
export class InfographicComponent implements OnInit {
  items: any = [];
  dumArray:any=[1,2,3,4,5,6,7,8,9];

  tutorials: any;
  currentTutorial = null;
  currentIndex = -1;
  title = '';

  page = 1;
  count = 0;
  pageSize = 9;
  resourcesinfographicDatas: any;
  resourceinfographicLoaded: boolean;
  envSiteName:any;
  scrollToTop: number;
  pos: number;
  MetaTagLoadedinfographic: boolean;
  MetaTagDatainfographic: any;

  constructor(private state: TransferState,private titleService: Title,private metaTagService: Meta,private httpclientService: HttpclientService,private canonical:CanonicalService,@Inject(PLATFORM_ID) private platformId: Object,
  @Inject(APP_ID) private appId: string) { 
    this.envSiteName=environment.siteName;
  }

  ngOnInit(): void {
    this.resourcesinfographicData();
    this.AllMetaTaginfographic();
  }
  resourcesinfographicData(): void
  {
    this.items = this.state.get(STATE_KEY_ITEMS, <any> []);
    this.resourceinfographicLoaded = false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetAllGlobalReourcesInfographics?siteName='+this.envSiteName+'&pageNumber='+this.page)
    .subscribe(
      items => {
        const platform = isPlatformBrowser(this.platformId) ?
            'in the browser' : 'on the server';
        this.resourcesinfographicDatas = items;
        this.count=this.resourcesinfographicDatas[0].TotalDataCount;
        this.resourceinfographicLoaded = true;
        this.state.set(STATE_KEY_ITEMS, <any> items);
        console.log("resources Video Datas", this.resourcesinfographicDatas);
        window.scrollTo(0,0);
      }
    );
  }
  resetUsers(): void {
    this.resourcesinfographicDatas = null;
    this.resourceinfographicLoaded = true;
  }
  handlePageChange(event): void {
    this.resetUsers();
    this.page = event;
    this.resourcesinfographicData();
    this.onActivate();
  }
  onActivate() {
    this.scrollToTop = window.setInterval(() => {
        this.pos = window.pageYOffset;
        console.log("pos value",this.pos)
        if (this.pos > 0) {
            window.scrollTo(0, this.pos - 40); // how far to scroll on each step
        } 
        else {
            window.clearInterval(this.scrollToTop);  
        }
    }, 2);
}
AllMetaTaginfographic(): void {
  if(this.MetaTagLoadedinfographic!=true){
  this.MetaTagLoadedinfographic = false;
  this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/Home/GetMetaCode?PubsiteName='+this.envSiteName+'&PageName='+'Infographics')
  .subscribe(
    items => {
      this.MetaTagDatainfographic = items[0];
      this.MetaTagLoadedinfographic=true;
      console.log('this.MetaTagDatainfographic',this.MetaTagDatainfographic);


        //seo code for tags
        this.canonical.CreateCanonicalLink();

        this.titleService.setTitle(this.MetaTagDatainfographic.PageTitle);

        this.metaTagService.updateTag(
          {name:'robots',content:'index,follow'}
        );

        this.metaTagService.updateTag(
        { name: 'og:description', content: this.MetaTagDatainfographic.OgDescription}
        );

        this.metaTagService.updateTag(
          { name: 'og:title', content: this.MetaTagDatainfographic.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'og:keywords', content: this.MetaTagDatainfographic.OgKeyWords}
        );

        this.metaTagService.updateTag(
          { name: 'og:image', content: this.MetaTagDatainfographic.OgImageURL}
        );

        this.metaTagService.updateTag(
          { name: 'og:image:alt', content: this.MetaTagDatainfographic.PageTitle}
        );

        this.metaTagService.updateTag(
          { name: 'description', content: this.MetaTagDatainfographic.OgDescription}
          );
  
          this.metaTagService.updateTag(
            { name: 'title', content: this.MetaTagDatainfographic.PageTitle}
          );
  
          this.metaTagService.updateTag(
            { name: 'keywords', content: this.MetaTagDatainfographic.OgKeyWords}
          );
  
          this.metaTagService.updateTag(
            { name: 'image', content: this.MetaTagDatainfographic.OgImageURL}
          );
  
          this.metaTagService.updateTag(
            { name: 'image:alt', content: this.MetaTagDatainfographic.PageTitle}
          );

        this.metaTagService.updateTag(
          { name: 'twitter:description', content: this.MetaTagDatainfographic.OgDescription}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:title', content: this.MetaTagDatainfographic.PageTitle}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:keywords', content: this.MetaTagDatainfographic.OgKeyWords}
        );
        this.metaTagService.updateTag(
          { name: 'twitter:image', content: this.MetaTagDatainfographic.OgImageURL}
        );
        //seo code for tags end
      });
    }
}
}
